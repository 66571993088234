<script>
export default {
  name: 'ModalIntegrations',
  props: {
    item: {
      type: Object
    },
    show: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      firstInputValue: '',
      secondInputValue: '',
      checkFormFiled: true,
      showModal: false,
      displayDetails: 'none',
      input: [],
      integrations: []
    }
  },
  created () {
    this.getIntegrations()
  },
  methods: {
    getIntegrations () {
      this.$store.dispatch('attemptGetIntegrations').then((data) => {
        this.integrations = data.integrations
      })
    },
    handleInputs () {
      const data = {
        firstInput: this.firstInputValue.trim(),
        secondInput: this.secondInputValue.trim()
      }
      this.$emit('add', data)
      this.firstInputValue = ''
      this.secondInputValue = ''
      this.$emit('close')
    },
    handleShowDetails (id, name, i) {
      const index = this.input.indexOf(id)

      if (index > -1) this.input.splice(index, 1)
      else {
        const data = {
          id: id,
          integration: name.toLowerCase()
        }
        if (this.integrations.indexOf(data.integration) > -1) {
          this.$store.dispatch('attemptGetIntegrationToken', data).then((data) => {
            this.item.dataToken[i].token = data.data.token
          }).then(() => {
            this.input.push(id)
          }
          )
        } else {
          this.input.push(id)
        }
      }
    }
  }
}
</script>
<template>
  <v-dialog :value="show" width="700px" max-width="100%">
    <v-card class="modal-Integrations--container">
      <div class="modal-Integrations--header">
        <div class="modal-Integrations--header---title">
          <v-avatar size="40px">
            <v-img :src="item.image" height="40" width="40"></v-img>
          </v-avatar>
          <h3>{{ item.title }}</h3>
        </div>
        <!--<v-icon class="clickable" color="#454545" @click="closeModal()">mdi-close</v-icon>-->
      </div>
      <div class="modal-description">
        <h4>
          {{ $t('global:description') }}
        </h4>
        <p>{{ item.description }}</p>
      </div>
      <div class="modal-tips">
        <ul v-for="item in item.tips" :key="item">
          <li v-html="item"></li>
        </ul>
      </div>
      <div class="modal-configuration">
        <h4>{{ $t('integration.modal.config') }}</h4>
        <div class="modal-configuration-message" v-if="!item.dataToken.length">
          <v-icon color="#8a6d3b">mdi-alert-circle-outline</v-icon>
          <span>
            {{ $t('integration.modal.disabled') }}
          </span>
        </div>
        <div v-if="item.dataToken.length" class="modal-configuration--input-container">
          <p>{{ $t('integration.modal.active') }}</p>
          <div class="modal-configuration--input" v-for="(dt, index) in item.dataToken" :key="dt._id">

            <div class="modal-configuration--input---remove">
              <p>{{ dt.name }}</p>
              <div>
                <v-btn class="btn transform-unset" outlined :color="getPrimaryColor" small
                  @click="$emit('remove', { integration_id: dt._id, integration_name: dt.name }), input = []">
                  <v-icon size="18px" class="mr-2" :color="getPrimaryColor">mdi-block-helper</v-icon>
                  {{ $t('skill.modal.delete:confirm') }}
                </v-btn>
                <v-btn class="btn transform-unset" dark :color="getPrimaryColor"
                  @click="handleShowDetails(dt._id, item.title, index)" small>
                  <v-icon size="18px" class="mr-2" color="#FFF">mdi-eye</v-icon>
                  {{ $t('integration.modal.viewtoken') }}
                </v-btn>
              </div>
            </div>
            <div>
              <input class="show-details" type="text" :value="dt.token" v-if="input.indexOf(dt._id) > -1" disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-message--input">
        <label>{{ $t('integration.modal.settings') }}</label>
        <div class="input-integration">
          <label>{{ item.firstLabel }}</label>
          <input type="email" v-model="firstInputValue" />
          <div v-if="!!item.secondLabel">
            <label>{{ item.secondLabel }}</label>
            <input type="text" v-model="secondInputValue" />
          </div>
        </div>
        <v-btn class="btn transform-unset"
          :disabled="!!item.secondLabel ? !!!firstInputValue || !!!secondInputValue : !!!firstInputValue"
          :dark="!!item.secondLabel ? !!firstInputValue && !!secondInputValue : !!firstInputValue"
          @click="handleInputs(), input = []" :color="getPrimaryColor">{{ $t('global:save') }}</v-btn>
      </div>
      <template v-if="!readonly">
        <div class="modal-message--actions-apply">
          <v-btn @click="$emit('close'), input = []" class="btn transform-unsetmr-4" dark :color="getPrimaryColor">{{
            $t('global:close') }}
          </v-btn>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-Integrations--container {
  position: relative;

  .modal-Integrations--header {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    background-color: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    h3 {
      font-size: 24px;
      margin-left: 10px;
      font-weight: 500;
      color: #58666E;
      font-family: 'Lato';
    }

    .modal-Integrations--header---title {
      display: flex
    }
  }

  .modal-Integrations--body {
    max-height: calc(100vh - 600px);
    overflow: auto;

    &.readonly {
      max-height: calc(100vh - 400px);
    }
  }

  .modal-message--actions-apply {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: 68px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);

    .btn.v-btn.v-size--default {
      text-transform: none;
      height: 36px;
      font-size: 14px;
    }

    .file-upload--container {
      margin-right: auto;
    }
  }

  .modal-description {
    text-align: left;
    padding: 20px 0px;
    border-bottom: 1px solid #bdbdba6b;
    margin: 0px 30px;

    h4 {
      font-family: 'Lato';
      color: #58666E;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    p {
      font-size: 13px;
      color: #58666E;
    }
  }

  .modal-tips {
    padding: 20px 50px;
    text-align: left;

    li {
      font-family: 'Lato';
      font-size: 13px;
      color: #58666E;
    }
  }

  .modal-configuration {
    padding: 20px 0px;
    margin: 0px 30px;
    text-align: left;

    h4 {
      font-family: 'Lato';
      color: #58666E;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    p {
      color: #58666E;
      font-family: 'Lato';
    }

    .modal-configuration-message {
      color: #8a6d3b;
      background-color: #FCF8E3;
      padding: 10px;
      border-radius: 5px;

      span {
        margin-left: 10px;
        font-size: 13px;
        font-family: 'Lato';
      }
    }
  }

  .modal-configuration--input-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    text-align: left;

    p {
      color: #58666E;
      font-family: 'Lato';
      font-size: 13px;
      padding: 15px 10px 0 15px;
    }
  }

  .modal-configuration--input {
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    text-align: left;

    p {
      color: #58666E;
      font-family: 'Lato';
      font-size: 13px;
      padding: 15px 10px 0 15px;
    }

    div {
      background-color: #fafbfc;
      padding: 10px;

      .hide-details {
        display: none;
      }

      .show-details {
        display: block;
      }

      input {
        background-color: #ffffff;
        height: 60px;
        padding-left: 10px;
        border: 1px solid rgb(237, 237, 237);
        color: #58666E;
        font-size: 14px;
        width: 100%;
      }
    }

    .modal-configuration--input---remove {
      display: flex;
      justify-content: space-between;
      background-color: #fafbfc;
      padding: 20px 15px 0px 5px;

      p {
        margin-top: -5px;
      }

      div {
        .btn:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .modal-message--input {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 16px;
    text-align: left;
    margin: 10px 30px 30px 30px;

    label {
      color: #58666E;
      font-family: 'Lato';
      font-size: 13px;
    }

    .input-integration {
      margin-top: 30px;
      color: #58666E;
      font-family: 'Lato';

      label {
        width: 100%;
        color: #58666E;
        font-family: 'Lato';
      }

      input {
        border: 1px solid #adadad;
        border-radius: 2px;
        padding: 5px;
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

}
</style>
